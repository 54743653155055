
    import React, {Component} from 'react';
    import architecture from '../../assets/3TierArch.png'

    class Home extends Component {
        render () {
        return (
            <div>
            <h1 style={{color:"white"}}>Welcome to Anycompany - Finance Application</h1>
            <img src={architecture} alt="3T Web App Architecture" style={{height:800,width:1650}} />
          </div>
        );
      }
    }

    export default Home;
